import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import hautausmaa from "../images/hautausmaa.jpg"
const Toimintakertomukset = ({ location: { pathname } }) => (
  <Layout pathname={pathname}>
    <SEO title="Suomen Hautaustoiminnan keskusliitto Ry - Toimintakertomukset, toimintasuunnitelmat ja pöytäkirjat" />
    <div className="hero-area full-container">
      <div className="page-banner parallax">
        <div className="container">
          <h1>Toimintakertomukset, toimintasuunnitelmat ja pöytäkirjat</h1>
        </div>
      </div>
    </div>
    <div className="spacer-60" />
    <div className="col-md-8 col-md-offset-2">
      <img className="pull-right padding-l25 w-6/12" src={hautausmaa} alt="" />
      <p>
        <a href="/doc/poytakirjat/poytakirja_2024.pdf">
          Liittokokouksen pöytäkirja 2024
        </a>
      </p>
      <p>
        <a href="/doc/toimintasuunnitelmat/toimintasuunnitelma_2024.pdf">
          Toimintasuunnitelma 2024
        </a>
      </p>
      <p>
        <a href="/doc/toimintakertomukset/toimintakertomus_2023.pdf">
          Toimintakertomus vuodelta 2023
        </a>
      </p>
      <p>
        <a href="/doc/poytakirjat/poytakirja_2023.pdf">
          Liittokokouksen pöytäkirja 2023
        </a>
      </p>
      <p>
        <a href="/doc/toimintakertomukset/toimintakertomus_2022.doc">
          Toimintakertomus vuodelta 2022
        </a>
      </p>
      <p>
        <a href="/doc/toimintasuunnitelmat/toimintasuunnitelma_2023.docx">
          Toimintasuunnitelma 2023
        </a>
      </p>
      <p>
        <a href="/doc/taloussuunnitelmat/taloussuunnitelma_2023.xlsx">
          Taloussuunnitelma 2023
        </a>
      </p>
      <p>
        <a href="/doc/toimintasuunnitelmat/toimintasuunnitelma_2022.pdf">
          Toimintasuunnitelma 2022
        </a>
      </p>
      <p>
        <a href="/doc/poytakirjat/poytakirja_2022.pdf">
          Liittokokouksen pöytäkirja 2022
        </a>
      </p>
      <p>
        <a href="/doc/poytakirjat/poytakirja_2021.pdf">
          Liittokokouksen pöytäkirja 2021
        </a>
      </p>
      <p>
        <a href="/doc/toimintakertomukset/toimintakertomus_2019.doc">
          Toimintakertomus vuodelta 2019
        </a>
      </p>
      <p>
        <a href="/doc/toimintakertomukset/toimintakertomus_2018.doc">
          Toimintakertomus vuodelta 2018
        </a>
      </p>
      {/* <p>
        <a href="doc/toimintakertomus_2013.pdf">
          Toimintakertomus vuodelta 2013
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2012.pdf">
          Toimintakertomus vuodelta 2012
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2011.pdf">
          Toimintakertomus vuodelta 2011
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2010.pdf">
          Toimintakertomus vuodelta 2010
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2009.pdf">
          Toimintakertomus vuodelta 2009
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2008.pdf">
          Toimintakertomus vuodelta 2008
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2007.pdf">
          Toimintakertomus vuodelta 2007
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2006.pdf">
          Toimintakertomus vuodelta 2006
        </a>
      </p>

      <p>
        <a href="doc/toimintakertomus_2005.pdf">
          Toimintakertomus vuodelta 2005
        </a>
      </p> */}
    </div>
  </Layout>
)

export default Toimintakertomukset
